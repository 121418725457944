import React from "react";
import Reas from "./Reas";
import Home from "./Home";
import Partners from "./Partners";
import Contact from "./Contact";
import Menu from "./Menu";
import Services from "./Services";

function Site() {
  return (
    <div>
      <Menu />
      <Home />
      <Reas />
      <Services />
      <Partners />
      <Contact />
    </div>
  );
}

export default Site;
