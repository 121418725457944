import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Site from "./Site";
import Thankyou from "./Thankyou";
function Routing() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Site />} index />
        <Route path="/Thankyou" element={<Thankyou />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Routing;
