import React from "react";
import { Link } from "react-router-dom";

function Thankyou() {
  return (
    <div className="container light">
      <div className="content thankyou-content">
        <div className="wrapper-1">
          <div className="wrapper-2">
            <h1 className="thankyou-title">Thank you !</h1>
            <p>Thank you for taking the time to reach out to us.</p>
            <p>
              We appreciate your interest, One of our team will get back to you
              as soon as possible.
            </p>
            <br />
            <p>Have a wonderful Time!</p>
            <br />
            <Link to="/" className="btn-cd">
              Go back home
            </Link>
          </div>
        </div>
      </div>

      <link
        href="https://fonts.googleapis.com/css?family=Kaushan+Script|Source+Sans+Pro"
        rel="stylesheet"
      />
    </div>
  );
}

export default Thankyou;
