import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Navigation } from "swiper";
import pb from "../Assets/BIS.png";
import dynamics from "../Assets/dynamics.png";
import mpp from "../Assets/mpp.png";
import md from "../Assets/dyn3.png";
import dwh from "../Assets/dwh.png";

function Services() {
  return (
    <div className="container-cd light" id="Services">
      <div className="content">
        <div class="header-content">
          <h2 className="big-title">Our services</h2>
          <div class="line dark"></div>
        </div>
        <div className="services-container">
          <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
            <SwiperSlide>
              <article className="service">
                <img src={pb} alt="BI and reporting solutions" />
                <p className="service-desc">
                  At our company, we understand the importance of leveraging
                  data to make informed decisions and drive business
                  performance. That's why we offer comprehensive BI and
                  reporting solutions to our clients.
                  <br></br>
                  Our services include: <br></br>
                  <ul className="list-s">
                    <li>
                      Data visualization and reporting: We help you create
                      intuitive, easy-to-use reports and dashboards that allow
                      you to quickly access and analyze your data.
                    </li>
                    <li>
                      Data integration and preparation: Our team can help you
                      integrate data from multiple sources and prepare it for
                      analysis, so you have a complete view of your business.
                    </li>
                    <li>
                      Data analysis and modeling: We have experts who can
                      analyze your data and build predictive models to help you
                      identify trends, patterns, and opportunities.
                    </li>
                    <li>
                      Custom dashboards and reports: We can create customized
                      reports and dashboards that align with your specific needs
                      and goals, giving you the power to make data-driven
                      decisions.
                    </li>
                  </ul>
                  By partnering with us, you can gain insights into your
                  business that were previously impossible to obtain. Our BI and
                  reporting solutions enable you to access and analyze data in
                  real time, so you can make informed decisions quickly and
                  confidently. Contact us today to learn more about our services
                  and how we can help your business succeed.
                </p>
              </article>
            </SwiperSlide>
            <SwiperSlide>
              <article className="service">
                <img src={dynamics} alt="Dynamics business central" />
                <p className="service-desc">
                  Offers comprehensive Microsoft Business Central services to
                  help businesses improve their operations and achieve their
                  goals. As a cloud-based enterprise resource planning (ERP)
                  solution, Microsoft Business Central enables companies to
                  manage their financials, sales, purchases, inventory, and
                  operations in one centralized location.
                  <br></br>
                  Our services include:<br></br>
                  <ul className="list-s">
                    <li>
                      Implementation and customization: We help you implement
                      Microsoft Business Central and customize it to meet your
                      specific business needs, so you have a solution that's
                      tailored to your unique requirements.
                    </li>
                    <li>
                      Integration and migration: We can help you integrate
                      Microsoft Business Central with your existing systems, and
                      migrate your data from other solutions to Microsoft
                      Business Central, ensuring a seamless transition.
                    </li>
                    <li>
                      Training and support: Our team of experts provides
                      training and support to help you get the most out of
                      Microsoft Business Central, so you can start using it to
                      manage your business effectively.
                    </li>
                    <li>
                      Reporting and analytics: We can help you create reports
                      and dashboards in Microsoft Business Central, so you can
                      easily access and analyze your business data and make
                      informed decisions.
                    </li>
                  </ul>
                  By partnering with Cloud Data for your Microsoft Business
                  Central needs, you can streamline your processes, improve
                  performance, and achieve your goals. Contact us today to learn
                  more about our services.
                </p>
              </article>
            </SwiperSlide>
            <SwiperSlide>
              <article className="service">
                <img src={dwh} alt="DataWarehouse solutions" />
                <p className="service-desc">
                  We specialize in providing businesses with efficient data
                  warehousing solutions. Our aim is to help you make informed
                  decisions by collecting, storing, and managing large amounts
                  of data in a centralized and organized repository.
                  <br></br>
                  Our services include:<br></br>
                  <ul className="list-s">
                    <li>
                      Data Integration: We help you integrate your data from
                      different sources, ensuring that all of your data is
                      stored in one centralized location.
                    </li>
                    <li>
                      Data Cleansing: We can help you clean and transform your
                      data, removing duplicates, correcting inaccuracies, and
                      making sure that your data is ready for analysis.
                    </li>
                    <li>
                      Data Modeling: Our team of experts can help you create a
                      data model that aligns with your business requirements,
                      making it easier to access and analyze your data.
                    </li>
                    <li>
                      Data Visualization: We can help you create interactive
                      dashboards and reports that provide insights into your
                      data and help you make informed decisions.
                    </li>
                  </ul>
                  You can have a complete and organized picture of your business
                  and make informed decisions that drive success. Contact us
                  today to learn more about our services.
                </p>
              </article>
            </SwiperSlide>
            <SwiperSlide>
              <article className="service">
                <img src={mpp} alt="Microsoft-Power-Platform" />
                <p className="service-desc">
                  Cloud Data provides businesses with expert Power Platform
                  services to maximize their productivity and efficiency.
                  <br></br>
                  Our services include: <br></br>
                  <ul className="list-s">
                    <li>
                      Custom Power Apps Development: We help you create custom
                      applications that streamline your business processes,
                      making work easier and faster.
                    </li>
                    <li>
                      Efficient Automation with Power Automate: Our team can
                      automate repetitive tasks, freeing up your time and
                      resources for more important tasks.
                    </li>
                    <li>
                      Insightful Data Visualization with Power BI: We create
                      interactive dashboards and reports that give you a clear
                      picture of your data, allowing you to make informed
                      decisions.
                    </li>
                    <li>
                      Conversational AI with Power Virtual Agents: Our team can
                      help you build virtual agents that can assist your
                      customers and employees, providing quick and accurate
                      responses.
                    </li>
                  </ul>
                  By working with Cloud Data, you can transform your business
                  processes and take advantage of the full potential of the
                  Microsoft Power Platform. Contact us today to learn more about
                  our services.
                </p>
              </article>
            </SwiperSlide>
            <SwiperSlide>
              <article className="service">
                <img src={md} alt="Microsoft Dynamics 365 " />
                <p className="service-desc">
                  Cloud Data specializes in delivering top-notch Microsoft
                  Dynamics 365 solutions to businesses of all sizes.
                  <br></br>
                  Our services include:<br></br>
                  <ul className="list-s">
                    <li>
                      Dynamics 365 Implementation: We can help you implement
                      Dynamics 365, customizing it to meet your unique business
                      requirements and streamlining your operations.
                    </li>
                    <li>
                      Dynamics 365 Integration: We can integrate Dynamics 365
                      with your existing systems, making it easier to access and
                      manage data across your organization.
                    </li>
                    <li>
                      Dynamics 365 Customization: Our team can tailor Dynamics
                      365 to your specific business processes, ensuring that it
                      works seamlessly with your operations.
                    </li>
                    <li>
                      Dynamics 365 Support and Maintenance: Our experts provide
                      ongoing support and maintenance services to keep Dynamics
                      365 running smoothly, allowing you to focus on your
                      business.
                    </li>
                  </ul>
                  With Cloud Data, you can transform your business operations
                  and achieve your goals with Microsoft Dynamics 365. Contact us
                  today to learn more about our Dynamics 365 services.
                </p>
              </article>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default Services;
