import React from "react";
import imgInto from "../Assets/imgIntro.png";

function Home() {
  return (
    <div className="container-cd light" id="home">
      <div className="content">
        <div className="home-container">
          <div className="text-home-content">
            <h2 className="header-title">Cloud Data</h2>
            <p className="intro-text">
              Cloud Data is your partner for IT business solutions. Our team of
              experts provides technology consulting, custom software
              development, and cloud solutions to help organizations achieve
              their goals. Let us help you improve operations, streamline
              processes, and drive growth.
            </p>
            <h3 className="sub-title">Building the businesses of tomorrow</h3>
            <button type="submit" className="btn-cd">
              <a href="#reas" className="btn-a">
                Find out more
              </a>
            </button>
          </div>
          <img src={imgInto} alt="Business" />
        </div>
      </div>
    </div>
  );
}

export default Home;
