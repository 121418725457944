import React from "react";
import jpmcc from "../Assets/jpmcc.png";
import ba from "../Assets/BA.jpg";
import BP from "../Assets/BP.png";
import RSAC from "../Assets/RSAC.jpg";
import SBF from "../Assets/SBF.png";
import microsoft from "../Assets/Microsoft.webp";
function Partners() {
  return (
    <div id="Partners" className="container-cd light">
      <div className="content">
        <div class="header-content">
          <h2 className="big-title">Our partners</h2>
          <div class="line dark"></div>
        </div>
        <p className="intro-text">
          From our early days, we've been providing reliable service to our
          clientele. <br />
          We've had the honor being the firm of choice of the following
          corporations:
        </p>
        <div className="elements-container">
          <div className="partner">
            <div className="logo-partner">
              <img src={jpmcc} alt="Jo Morgan chase" />
            </div>
            <h3 className="partner-name">Jo Morgan chase</h3>
          </div>
          <div className="partner">
            <div className="logo-partner">
              <img src={microsoft} alt="Microsoft" />
            </div>
            <h3 className="partner-name">Microsoft</h3>
          </div>
          <div className="partner">
            <div className="logo-partner">
              <img src={ba} alt="Bank of america" />
            </div>
            <h3 className="partner-name">Bank of america</h3>
          </div>
          <div className="partner">
            <div className="logo-partner">
              <img src={RSAC} alt="Reliance steel and aluminum" />
            </div>
            <h3 className="partner-name"> Reliance steel and aluminum</h3>
          </div>
          <div className="partner">
            <div className="logo-partner">
              <img src={SBF} alt="Smith Brothers" />
            </div>
            <h3 className="partner-name">Smith Brothers</h3>
          </div>
          <div className="partner">
            <div className="logo-partner">
              <img src={BP} alt="Bridge Partners" />
            </div>
            <h3 className="partner-name">Bridge Partners</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partners;
