import React from "react";
import LOGO from "../Assets/LOGO.png";
import mail from "../Assets/email.png";
import phone from "../Assets/phone.png";

function contact() {
  return (
    <div className="container-cd dark" id="Contact">
      <div className="content">
        <div class="header-content">
          <h2 className="big-title big-title-light">Let's work together</h2>
          <div class="line light"></div>
        </div>
        <div className="form-container">
          <form
            className="form-content"
            action="https://formsubmit.co/contact@cdpro.co"
            method="POST"
          >
            <input type="hidden" name="_captcha" value="false"></input>
            <input
              type="hidden"
              name="_next"
              value="https://cdpro.co/Thankyou"
            ></input>
            <input
              type="hidden"
              name="_autoresponse"
              value="your custom message"
            ></input>
            <input
              type="text"
              name="fullName"
              placeholder="Your Full Name"
              required
            />
            <input
              type="text"
              name="yourEmail"
              placeholder="Email Address"
              required
            />
            <input type="text" name="subject" placeholder="Subject" required />
            <textarea
              name="message"
              rows="7"
              placeholder="Your message"
              required
            ></textarea>
            <button type="submit" className="btn-cd">
              Send message
            </button>
          </form>
        </div>
      </div>
      <div className="info-contact">
        <div className="logo-info">
          <img src={LOGO} alt="logo" />
        </div>
        <div className="infos-addr-content">
          <img src={mail} alt="" className="icon" />
          <p className="info-text">contact@cdpro.co</p>
        </div>
        <div className="infos-addr-content">
          <img src={phone} alt="" className="icon" />
          <p className="info-text">+1 (714) 800-9491</p>
        </div>
      </div>
    </div>
  );
}

export default contact;
