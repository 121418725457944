import React from "react";
import exp from "../Assets/expertise.png";
import comm from "../Assets/commitment.png";
import val from "../Assets/value.png";

function Reas() {
  return (
    <div className="container-cd dark" id="reas">
      <div className="content">
        <div class="header-content">
          <h2 className="big-title big-title-light">What makes us awesome</h2>
          <div class="line light"></div>
        </div>
        <div className="elements-container">
          <div className="principale">
            <div className="icon-containe">
              <img src={exp} alt="Expertise" />
            </div>
            <h3 className="principle-title">Expertise</h3>
            <p className="text-light">
              We strive to remain at the forefront of innovation and development
              with a team of highly qualified and experienced professionals.
            </p>
          </div>
          <div className="principale">
            <div className="icon-containe">
              <img src={comm} alt="Commitment" />
            </div>
            <h3 className="principle-title">Commitment</h3>
            <p className="text-light">
              We are committed to continuing to offer our clients high-quality
              services because we take great pride in delivering an excellent
              service.
            </p>
          </div>
          <div className="principale">
            <div className="icon-containe">
              <img src={val} alt="Value" />
            </div>
            <h3 className="principle-title">Value</h3>
            <p className="text-light">
              We place a high value on our clients and are dedicated to ensuring
              that they are completely satisfied with our services.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reas;
