import React from "react";
import logos from "../Assets/logo-small.png";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";

function Menu() {
  return (
    <div className="container-cd light">
      <div className="nav-content">
        <Navbar expand="lg" className="justify-content-space-between">
          <Navbar.Brand href="#">
            <img src={logos} alt="Cloud data" className="nav-elem-img" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="ms-auto justify-content-space-between nav-c-m-w">
              <Nav.Link href="#home" className="nav-elem">
                HOME
              </Nav.Link>
              <Nav.Link href="#Services" className="nav-elem">
                SERVICES
              </Nav.Link>
              <Nav.Link href="#Partners" className="nav-elem">
                PARTNERS
              </Nav.Link>
              <Nav.Link href="#Contact" className="nav-elem">
                CONTACT US
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
}

export default Menu;
